.mainContainer {
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.containerCards {
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.chartsContainer {
    display: flex;
    gap: 1rem;
    flex-direction: column;
}

.containerFilters {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.filterDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: .2rem;
}

.filterLine {
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.filterButtons {
    display: flex;
    gap: .2rem;
}

.lineChart {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.lineChartContainer {
    width: 100%;
    display: flex;
    gap: 1rem;
}

.checkboxContainer {
    width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.lineChartFilters {
    display: flex;
    gap: 1rem;
}

.informationText {
    color: #8E8E93;
    font-size: .7rem;
    padding-left: .5rem;
}

.lineChartMonthFilters {
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.filters {
    display: flex;
    gap: 1rem;
}

.chartContainer {
    overflow-x: auto;
    white-space: nowrap;
}

.dropdownDivider {
    width: 100%;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.dropdownIcon {
    cursor: pointer;
    transition: .3s;

    &:hover {
        transform: scale(1.3);
    }
}