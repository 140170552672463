.lineChart {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.lineChartMonthFilters {
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.filterDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: .2rem;
}

.informationText {
    color: #8E8E93;
    font-size: .7rem;
    padding-left: .5rem;
}

.filterButtons {
    display: flex;
    gap: .2rem;
}

.lineChartContainer {
    width: 100%;
    display: flex;
    gap: 1rem;
}