.mainContainer {
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.globalsFilters {
    width: 100;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cardTopFilter {
    display: flex;
    flex-direction: column;
    gap: .3rem;
    width: 275px;
}

.generateReport {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.selectComponent {
    display: flex;
    flex-direction: column;
    gap: .3rem;
}

.informationText {
    color: #8E8E93;
    font-size: .7rem;
    padding-left: .5rem;
}