.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.filters {
    width: 100%;
    display: flex;
    gap: 1rem;
}