.mainContainer {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.containerFilters {
    display: flex;
    gap: 1rem;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.filterButtons {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
}

.lineChartContainer {
    width: 100%;
}