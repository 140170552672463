.cardContainer {
    width: 100%;
    display: flex;
    gap: 1rem;
}

.lightOrangeCard {
    background-color: #FFA83E;
    color: #333;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
}

.darkOrangeCard {
    background-color: #FFB957;
    color: #333;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
}